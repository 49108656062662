import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

import "../styles/LanguageChooser.scss";


function LanguageChooser() {
  const { i18n } = useTranslation();

    useEffect(() => {
        const language = window.location.pathname.includes("/en") ? "en" : "de";
        i18n.changeLanguage(language);
        if(language === "en"){
            setENButtonStyle('enButtonClicked');
            setDEButtonStyle('deButton');
        }
    }, []);

    const [deButtonStyle, setDEButtonStyle] = useState("deButtonClicked");
    const [enButtonStyle, setENButtonStyle] = useState("enButton");



  const changeLanguageToDe = (event) => {
        const language = event.target.value;
    i18n.changeLanguage(language);
    //console.log( "lng" + language);
    setDEButtonStyle('deButtonClicked');
    setENButtonStyle('enButton');
      //window.history.pushState(`/de/${window.location.pathname}`);

  };

    const changeLanguageToEN = (event) => {
        const language = event.target.value;
        i18n.changeLanguage(language);
        //console.log( "lng" + language);
        setENButtonStyle('enButtonClicked');
        setDEButtonStyle('deButton');
    //window.history.pushState(`/en/${window.location.pathname}`);
    };

  return (
    <div className="languageChooserContainer">
        <button className={enButtonStyle} onClick={changeLanguageToEN} value="en" name="language">
          EN
        </button>
        <button className={deButtonStyle} onClick={changeLanguageToDe} value="de" name="language">
          DE
        </button>
    </div>
  );
}

export default LanguageChooser;
