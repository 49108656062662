import i18n from "i18next";
//import Backend from "i18next-xhr-backend";
import backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import detector  from 'i18next-browser-languagedetector';


i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init({
        lng: "de",
        backend: {
            /* translation file path */
            loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
        },
        detection: {
            order: ["querystring", "cookie", "localStorage", "navigator"],
            cache: ["localStorage", "cookie"]
        },
       /* resources: {
            en: {
                translation: enTranslation,
            },
            de: {
                translation: deTranslation,
            },
        },*/
        // urlTranslation: {
        //     enabled: true,
        //     langParam: 'lng',
        //     languages: ['en', 'de']
        //     languages: ['en', 'de']
        // },
        fallbackLng: "de",
        debug: true,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            // formatSeparator: ",",
        },
        react: {
            wait: true,
        },
    });

export default i18n;
