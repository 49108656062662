import React from "react";
import {Link} from "react-router-dom"
import Navigation from "./Navigation";
import MobileNavigation from "./MobileNavigation";
import btLogo from "../../images/black_tusk_logo.svg";
import "../../styles/Navbar.scss";
import LanguageChooser from "../LanguageChooser";
import { useTranslation } from "react-i18next";


function Navbar() {
    const { t } = useTranslation();
  return (
    <div className="navBarContainer" data-testid="navBar">
       <Link className="navbarLogoLink" to={t('homeLink')}>
        <img className="navbarLogo"  alt="Black Tusk Logo" src={btLogo} />
      </Link>
        <Navigation/>
        <LanguageChooser/>
        <MobileNavigation/>
    </div>
  );
}

export default Navbar;
