import React from "react";
import btLogo from "../images/black_tusk_logo.svg";
import linkedinLogo from '../images/LinkedinTreeIcon.png';
import instLogo from '../images/InstaTreeIcon.png'
import xingLogo from '../images/xing-icon.png'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import arrow from "../images/arrow.png"

import "../styles/Footer.scss";



function Footer() {
    const {t} = useTranslation();
    return (
        <div className={'footerContainer'}>
            <img
                className='btLogo'
                alt="Black Tusk"
                src={btLogo}
            />
            <div className={'linktsToSides'}>
                {/*<Link to="/de/dmea24Gewinnspiel" href="/de/dmea24Gewinnspiel" >🎉 {t('dmeaOverlayButtonToCompetition')} 🎉*/}
                {/*</Link>*/}
                <Link className={'linksToSideContent'} to={t('aboutUsLink')}>
                        <span>{t("aboutUs")}</span>
                        {/*<img className={'toPageIcon'} alt={'to page'} src={arrow}/>*/}
                    </Link>
                <Link className={'linksToSideContent'} to={t('careerLink')}>
                    <span>{t("career")}</span>
                    {/*<img className={'toPageIcon'} alt={'to page'} src={arrow}/>*/}
                </Link>
                <Link className={'linksToSideContent'} to={t('faqsLink')}>
                    <span>{t("faqs")}</span>
                    {/*<img className={'toPageIcon'} alt={'to page'} src={arrow}/>*/}
                </Link>
            </div>
            <div className={'followUs'}>
                <span>{t("footerFollowUs")}</span>
               <div className={'followUsIcons'}>
                   <a href="https://at.linkedin.com/company/black-tusk-gmbh"
                      target="_blank"
                      rel="noopener noreferrer">
                       <img
                           className={'socialMediaIcon'}
                           alt={'LinkedIn Logo'}
                           src={linkedinLogo}/>
                   </a>
                   <a href="https://www.xing.com/pages/black-tusk"
                      target="_blank"
                      rel="noopener noreferrer">
                       <img
                           className={'socialMediaIcon'}
                           alt={'Xing Logo'}
                           src={xingLogo}/>
                   </a>
                   <a href="https://www.instagram.com/blacktusk_eu/"
                      target="_blank"
                      rel="noopener noreferrer">
                       <img
                           className={'socialMediaIcon'}
                           alt={'Instgram Logo'}
                           src={instLogo}/>
                   </a>
               </div>
            </div>
            <a className={'navbarEmailIconLink'} href="mailto:info@blacktusk.eu">
                {/*<img className={'navbarEmailIcon'} src={emailIcon} alt="EmailIcon" />*/}
                Support: info@blacktusk.eu
            </a>
            <Link className={'linkToImpressum'} to={t('impressumLink')}>
                {t("footerImpressum")}
            </Link>
            <div className={"scrollToTop"}>
                <button
                    onClick={() => {
                        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                    }}
                    id="myBtn"
                    className={'toTopButton'}
                >
                    TOP
                </button>
            </div>
        </div>
    );
}

export default Footer;





// <label className={'followUsLabel'}>
//     {t("footerFollowUs")}
//     <div className={'folowUsImagesContainer'}>
//         <a href="https://at.linkedin.com/company/black-tusk-gmbh"
//            target="_blank"
//            rel="noopener noreferrer">
//             <img className={'linkedinLogo'}
//                  alt={'LinkedIn Logo'}
//                  src={linkedinLogo}/>
//         </a>

//     </div>
// </label>

