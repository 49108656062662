import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./i18n";
import "./index.scss";

import App from "./App";
import {HelmetProvider} from "react-helmet-async";

ReactDOM.render(
    // <React.StrictMode>

        <Suspense fallback={<div>Loading... </div>}>
            <HelmetProvider>
            <App/>
            </HelmetProvider>
        </Suspense>,

    // </React.StrictMode>,
    document.getElementById("root")
);
