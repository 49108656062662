import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import "../../styles/Navbar.scss";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import LanguageChooser_Mobile from "../LanguageChooser_Mobile";
import LanguageChooser from '../LanguageChooser'

function MobileNavigation() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const hamburgerIcon = (
    <AiOutlineMenu
      className="hamburger"
      size="30px"
      color="#565656"
      onClick={() => setOpen(!open)}
    />
  );
  const closeIcon = (
    <AiOutlineClose
      className="hamburger"
      size="30px"
      color="#565656"
      onClick={() => setOpen(!open)}
    />
  );

  const MobileNavLinks =() => {
    return (
        <div>
          <ul data-aos="fade-down" data-aos-duration="200">
            {/*<li >*/}
            {/*  <Link to={t('homeLink')} onClick={() => setOpen(!open)} >{t("home")}</Link>*/}
            {/*</li>*/}
            <li>
              <Link to={t('b2bProductsLink')} onClick={() => setOpen(!open)}>{t("b2bProducts")} - {t('products')}</Link>
            </li>
            <li>
              <Link to={t('b2cProductsLink')} onClick={() => setOpen(!open)}>{t("b2cProducts")} - {t('products')}</Link>
            </li>
            <li >
              <Link to={t('consultingLink')} onClick={() => setOpen(!open)}>{t("consulting")}</Link>
            </li>
            <li >
              <Link to={t('rAndDLink')} onClick={() => setOpen(!open)}>{t("ResearchAndDevelopment")}</Link>
            </li>
            {/*<LanguageChooser_Mobile />*/}
          </ul>
        </div>
    );
  }

  return (
    <div>
      <nav className="mobileNavigation">
        {open ? closeIcon : hamburgerIcon}
        {open && <MobileNavLinks />}
      </nav>
    </div>
  );
}



export default MobileNavigation;
