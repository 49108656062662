import React, { useEffect, lazy} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Footer from "./components/Footer";

import "./App.scss";
import "./fonts/D-DIN-Bold.woff";
import "./fonts/D-DIN-Italic.woff";
import "./fonts/D-DIN.woff";
import "./fonts/D-DINCondensed-Bold.woff";
import "./fonts/D-DINCondensed.woff";
import Navbar from "./components/NavBar/Navbar";
import {useTranslation} from "react-i18next";
import CookieConsent from "react-cookie-consent";


function App() {
    const { i18n} = useTranslation();
    const {t} = useTranslation();

    const Home = lazy(() => import("./components/pages/Home"));
    const AboutUs = lazy(() => import("./components/pages/AboutUs"));
    const Consulting = lazy(() => import("./components/pages/Consulting"));
    const ResearchAndDevelopment = lazy(() => import("./components/pages/ResearchAndDevelopment"));
    const Career = lazy(() => import("./components/pages/Career"));
    const Impressum = lazy(() => import("./components/pages/Impressum"));
    const B2BProducts = lazy(() => import("./components/pages/B2BProducts"));
    const B2CProducts = lazy(() => import("./components/pages/B2CProducts"));

    const FAQs = lazy(() => import("./components/pages/FAQ"));
    //const Cookiebanner = lazy(() => import("react-cookie-consent"))

    const NotFound = lazy(() => import("./components/pages/NotFound"));
    const Linktree = lazy(() => import("./components/pages/Linktree"));

    // const CompetitionPage = lazy(() => import('./components/pages/CompetitionPage'))



    useEffect(() => {
        const language = window.location.pathname.includes("/en") ? "en" : "de";
        i18n.changeLanguage(language);
    },[]);

    return (
        <BrowserRouter >
            <Navbar/>
            <Routes>
                <Route exact path='/' element={<Home/>}/>
                <Route exact path="/en/" element={<Home/>}/>
                <Route exact path="/de/" element={<Home/>}/>

                <Route exact path="en/about-us" element={<AboutUs/>}/>
                <Route exact path="de/Ueber-uns" element={<AboutUs/>}/>

                <Route exact path="en/consulting" element={<Consulting/>}/>
                <Route exact path="de/Beratung" element={<Consulting/>}/>

                <Route exact path="en/research-development" element={<ResearchAndDevelopment/>}/>
                <Route exact path="de/forschung-entwicklung" element={<ResearchAndDevelopment/>}/>

                <Route exact path="en/b2bProducts" element={<B2BProducts/>}/>
                <Route exact path="de/b2bProdukte" element={<B2BProducts/>}/>

                <Route exact path="en/b2cProducts" element={<B2CProducts/>}/>
                <Route exact path="de/b2cProdukte" element={<B2CProducts/>}/>

                <Route exact path="en/career" element={<Career/>}/>
                <Route exact path="de/karriere" element={<Career/>}/>

                <Route exact path="en/faqs" element={<FAQs/>}/>
                <Route exact path="de/faqs" element={<FAQs/>}/>

                <Route exact path="en/imprint" element={<Impressum/>}/>
                <Route exact path="de/impressum" element={<Impressum/>}/>

                <Route path='*' element={<NotFound />} status = {404}/>

                <Route path='de/Linktree' element={<Linktree />}/>
                <Route path='en/Linktree' element={<Linktree />}/>

                {/*<Route exact path='en/contactUs' element={<CompetitionPage/>}/>*/}
                {/*<Route exact path='de/Kontaktformular' element={<CompetitionPage/>}/>*/}



            </Routes>
           <footer id={'footer'}>
               <Footer/>
           </footer>
            <CookieConsent
                location="bottom"
                buttonText={t('cookieButtonText')}
                cookieName="myAwesomeCookieName2"
                style={{background: "#2B373B", opacity: '0.8'}}
                buttonStyle={{backgroundColor: '#009fe3', color: "white", fontSize: "13px", width: '80%'}}
                expires={150}
                buttonClasses={'cookieButton'}
            >
                {t('coockieText')}
            </CookieConsent>
        </BrowserRouter>

    );
}

export default App;
