import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../styles/Navbar.scss";

function NavLinks() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={"navLinks"}>
      <ul className="navContainer">
        <li className={'navChild'}>
          <div className="dropdown">
            <span className={'dropbtn'} onClick={handleOpen}>{t('products')}</span>
                 <div className="dropdown-content">
                   <Link to={t('b2bProductsLink')}>{t("b2bProducts")} - {t('products')}</Link>
                   <Link className={'secondLink'} to={t('b2cProductsLink')}>{t("b2cProducts")} - {t('products')}</Link>
                 </div>
          </div>
        </li>
        <li className="navChild">
          <Link to={t('consultingLink')}>{t("consulting")}</Link>
        </li>
        <li className="navChild">
          <Link to={t('rAndDLink')}>{t("ResearchAndDevelopment")}</Link>
        </li>
      </ul>
    </div>
  );
}

export default NavLinks;
