import React from "react";
import NavLinks from "./NavLinks";
import "../../styles/Navbar.scss";

function Navigation() {
  return (
    <div className="webNavigationMain">
      <nav className="webNavigation">
        <NavLinks />
      </nav>
    </div>
  );
}

export default Navigation;
